export const theme = {
  primary: "#C1AD95",
  secondary: "#302926",
  lighter: "#fff",
  backgroundLight: "#FFF",
  primaryHover: "#343078",
  secondaryRGBA: (transparent: boolean) => `rgba(48, 41, 38, ${transparent})`,
  mobile: "576px",
  marginXS: "6px",
  marginS: "12px",
  marginM: "18px",
  marginL: "24px",
  marginXL: "30px",
  marginXXL: "36px",

  fontXS: "0.5rem",
  fontS: "1rem",
  fontM: "1.5rem",
  fontL: "2rem",
  fontXL: "2.5rem",
}
